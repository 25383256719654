import React, { useContext } from "react";
import { Link } from "react-router-dom";
import UserContext from "../../UserContext";

import SignIn from "../SignIn/SignIn";

import "./Home.scss";

function Home() {
  const user = useContext(UserContext);

  return (
    <div className="Home container">
      <h1>Hi, welcome to Bruce's clock applications</h1>
      {user.token && (
        <h3>
          You are signed in! <Link to="/dashboard">Go to dashboard</Link>
        </h3>
      )}
      <SignIn />
      <p>
        Please keep in mind that this project is only in its{" "}
        <strong>early alpha development stage</strong>, which means there will
        be many bugs, but also many features yet to come. Use at your own risk.
      </p>
    </div>
  );
}

export default Home;
