import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";
import axios from "../../request";

import Events from "../Events/Events";
import Settings from "../Settings/Settings";

import { ReactComponent as Cog } from "../../assets/icons/cog.svg";

import "./Clock.scss";

let timerId;

let lastRefreshed = moment();

function Clock() {
  // Simply used to trigger new render
  const changeInt = useState(0)[1];

  const [name, setName] = useState("");
  const [events, setEvents] = useState([]);
  const [quote, setQuote] = useState({});
  const [error, setError] = useState("");

  // Whether to display seconds on the clock
  const [displaySeconds, setDisplaySeconds] = useState(false);
  const timeFormat = displaySeconds ? "h:mm:ssa" : "h:mma";

  const setSeconds = ({ target }) => {
    const { checked } = target;
    setDisplaySeconds(checked);
    localStorage.setItem("displaySeconds", checked);
  };

  // Whether to display the progress bar
  const [displayProgressBar, setDisplayProgressBar] = useState(false);

  const setProgressBar = ({ target }) => {
    const { checked } = target;
    setDisplayProgressBar(checked);
    localStorage.setItem("displayProgressBar", checked);
  };

  // Background colour
  const [backgroundColor, updateBackgroundColor] = useState({
    r: 46,
    g: 122,
    b: 228,
  });

  const displayColor = (rgb) => {
    updateBackgroundColor(rgb);
    const styles = document.documentElement.style;
    styles.setProperty("--r", rgb.r);
    styles.setProperty("--g", rgb.g);
    styles.setProperty("--b", rgb.b);
  };

  const updateColor = ({ rgb }) => {
    displayColor(rgb);
    localStorage.setItem("mainColor", JSON.stringify(rgb));
  };

  const { r, g, b } = backgroundColor;
  const backgroundRGB = `rgb(${r},${g},${b})`;

  const [settingsOpen, changeSettingsOpen] = useState(false);
  const openSettings = (e) => {
    changeSettingsOpen(true);
  };

  const { id } = useParams();

  const getEvents = async () => {
    try {
      lastRefreshed = moment();
      const { data } = await axios.get(`/api/${id}`);
      const { events, quote, name } = data;
      setEvents(events);
      setQuote(quote);
      setName(name);
      console.log(moment().format(), "Fetched Events");
    } catch (e) {
      setError("Sorry but an error has occurred. ");
    }
  };

  useEffect(() => {
    // Trigger new render every second
    timerId = setInterval(() => changeInt((i) => ++i), 1000);
    // Display seconds
    const displaySeconds = localStorage.getItem("displaySeconds");
    setDisplaySeconds(displaySeconds === "true");
    // Display progress bar
    const displayProgressBar = localStorage.getItem("displayProgressBar");
    setDisplayProgressBar(displayProgressBar === "true");
    // Grab saved colour
    const rgb = JSON.parse(localStorage.getItem("mainColor"));
    if (rgb) displayColor(rgb);

    document.addEventListener(
      "visibilitychange",
      () => {
        // If user no longer sees page, pause loop
        if (document.hidden) clearInterval(timerId);
        else timerId = setInterval(() => changeInt((i) => ++i), 1000);
      },
      false
    );

    // Initial Events Fetching
    getEvents();

    return () => clearInterval(timerId);
    // eslint-disable-next-line
  }, [id]);

  // Re-fetch events every 6 hours
  if (moment().diff(lastRefreshed, "hours") >= 6) getEvents();

  return (
    <div className="Clock">
      <div className="Clock--content" style={{ background: backgroundRGB }}>
        <div className="container">
          <h1 id="name">{name}</h1>
          <h1 id="time" className="Clock--time">
            {moment().format(`${timeFormat}, D/M/YY`)}
          </h1>
          <Events
            events={events}
            displayProgressBar={displayProgressBar}
            quote={quote}
            error={error}
          />
          <Cog className="Clock--cog" alt="settings" onClick={openSettings} />
          <p className="Clock--footer">
            <a
              target="_BLANK"
              rel="noopener noreferrer"
              href="https://thebrucecweb.com"
            >
              Made by Bruce
            </a>{" "}
            ·{" "}
            <a
              target="_BLANK"
              rel="noopener noreferrer"
              href="mailto:bruce@thebrucecweb.com?subject=IMPORTANT Clock Problem"
            >
              Report an issue
            </a>{" "}
            ·{" "}
            <a
              target="_BLANK"
              rel="noopener noreferrer"
              href="https://clock-app.thebrucecweb.com"
            >
              Create your own
            </a>
          </p>
        </div>
      </div>
      <Settings
        settingsOpen={settingsOpen}
        changeSettingsOpen={changeSettingsOpen}
        backgroundColor={backgroundColor}
        updateColor={updateColor}
        displayProgressBar={displayProgressBar}
        setProgressBar={setProgressBar}
        displaySeconds={displaySeconds}
        setSeconds={setSeconds}
      />
    </div>
  );
}

export default Clock;
