import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../request";
import jwtDecode from "jwt-decode";

import Routes from "../Routes";
import UserContext from "../../UserContext";

import "./App.scss";

function App() {
  const [user, setUser] = useState({});
  const history = useHistory();

  useEffect(() => {
    class User {
      constructor() {
        this.token = "";
        this.exp = new Date(0);
      }
      save(token) {
        const { exp } = jwtDecode(token);
        if (new Date(exp * 1000) < new Date()) {
          console.warn("Auth token has expired");
          localStorage.removeItem("Authorization");
        } else {
          this.exp = exp;
          this.token = token;
          this.auth = { authorization: `Bearer ${token}` };
          setUser(this);
        }
      }
      store(token) {
        localStorage.setItem("Authorization", token);
        this.save(token);
      }
      onLoad() {
        const token = localStorage.getItem("Authorization");
        if (token) this.save(token);
        else setUser(this);
      }
    }
    new User().onLoad();

    axios.interceptors.response.use(
      (res) => res,
      (err) => {
        console.error(err);
        if (err.response) {
          const { data, status } = err.response;
          if (status === 401) {
            history.replace("/signin");
          } else {
            console.log(data);
            // Flash something went wrong
          }
        } else {
          return Promise.reject(err);
        }
      }
    );
  }, [history]);

  return (
    <UserContext.Provider value={user}>
      <div className="App">
        <Routes />
      </div>
    </UserContext.Provider>
  );
}

export default App;
