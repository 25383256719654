import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./Home/Home";
import SignIn from "./SignIn/SignIn";
import Clock from "./Clock/Clock";
import Dashboard from "./Dashboard/Dashboard";
import Calendars from "./Calendars/Calendars";

function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/signin">
        <SignIn />
      </Route>
      <Route
        exact
        path={["/dashboard", "/:id/settings"]}
        render={({ match }) => <Dashboard match={match} />}
      />
      >
      <Route path="/:id/calendars">
        <Calendars />
      </Route>
      <Route path="/:id">
        <Clock />
      </Route>
    </Switch>
  );
}

export default Routes;
