import React, { useEffect, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import AdminSettings from "../AdminSettings/AdminSettings";

import UserContext from "../../UserContext";
import axios from "../../request";

import { ReactComponent as Cog } from "../../assets/icons/cog.svg";

import "./Dashboard.scss";

function Dashboard({ match }) {
  const user = useContext(UserContext);
  const history = useHistory();

  const [clocks, setClocks] = useState([]);

  const deleteClock = async (clockId) => {
    const { data } = await axios.delete(`/api/${clockId}`, {
      headers: user.auth,
    });
    if (data.error) console.error(data.error);

    setClocks((clocks) => clocks.filter((clock) => clock._id !== clockId));
  };

  useEffect(() => {
    (async () => {
      if (Object.entries(user).length !== 0) {
        if (user.token) {
          const { data } = await axios.get("/api/clocks", {
            headers: user.auth,
          });
          setClocks(data.clocks);
        } else {
          history.replace("/signin");
        }
      }
    })();
  }, [user, history]);

  const [clockName, updateClockName] = useState("");

  const handleClockNameChange = (e) => {
    updateClockName(e.target.value);
  };

  const handleClockNameSubmit = async (e) => {
    e.preventDefault();
    const { data } = await axios.post(
      "/api/new",
      { clockName },
      { headers: user.auth }
    );
    updateClockName("");
    history.push(`/${data.clockId}/calendars`);
  };

  const settingsOpen = match.path === "/:id/settings";
  const { id } = match.params;

  return (
    <div>
      <div className="Dashboard container">
        <p>
          <Link to="/">Return Home</Link>
        </p>

        <h2>Create Clock: </h2>
        <form onSubmit={handleClockNameSubmit}>
          <label htmlFor="name">Name for the new clock: </label>
          <input
            id="name"
            type="text"
            name="name"
            value={clockName}
            onChange={handleClockNameChange}
          />

          <button className="primary">Submit</button>
        </form>

        <h2>Clocks: </h2>
        {clocks.map((clock) => (
          <div key={clock._id} className="Dashboard--clock">
            <p
              style={{
                color:
                  Date.now() - new Date(clock.lastFetched) <
                  7 * 24 * 60 * 60 * 1000
                    ? "#000"
                    : "#ff4545",
              }}
            >
              <Link
                className="Dashboard--clockLink"
                to={`/${clock._id}`}
                target="_blank"
              >
                {clock.name}
              </Link>{" "}
            </p>
            <Link to={`/${clock._id}/settings`}>
              <Cog className="cog" />
            </Link>
          </div>
        ))}
      </div>

      {settingsOpen && (
        <AdminSettings
          user={user}
          deleteClock={deleteClock}
          clock={clocks.find((clock) => clock._id === id)}
        />
      )}
    </div>
  );
}

export default Dashboard;
