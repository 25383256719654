import React from "react";
import { useHistory, Link } from "react-router-dom";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

import "./AdminSettings.scss";

function AdminSettings({ clock, deleteClock }) {
  const history = useHistory();
  const close = e => {
    history.replace("/dashboard");
  };

  const handleDeleteClock = async e => {
    try {
      const confirmed = window.confirm(
        "Are you sure you want to delete this clock?"
      );
      if (!confirmed) return;
      await deleteClock(clock._id);
      history.replace("/dashboard");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="AdminSettings">
      <div className="AdminSettings--settings container">
        <Close className="AdminSettings--settings__close" onClick={close} />
        <h1>Settings for {clock && clock.name}</h1>
        <p>
          {clock && (
            <Link to={`/${clock._id}/calendars`}>Change Calendars</Link>
          )}
        </p>
        <button onClick={handleDeleteClock} className="danger">
          Delete Clock
        </button>
      </div>
    </div>
  );
}

export default AdminSettings;
