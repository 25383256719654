import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "../../request";

import GoogleLogin from "react-google-login";
import UserContext from "../../UserContext";

import "./SignIn.scss";

function SignIn() {
  const user = useContext(UserContext);
  const history = useHistory();

  const responseGoogleSuccess = async (code) => {
    const { data } = await axios.post("/code", code);
    user.store(data.token);
    history.push("/dashboard");
  };

  const responseGoogleFailure = (res) => {
    console.error(res);
  };

  return (
    <div className="SignIn container">
      <p>
        Please sign in here with Google to link your own calendar and create
        your own clock:
      </p>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_PUBLIC_KEY}
        accessType="offline"
        scope="openid https://www.googleapis.com/auth/calendar.readonly"
        responseType="code"
        onSuccess={responseGoogleSuccess}
        onFailure={responseGoogleFailure}
      />
    </div>
  );
}

export default SignIn;
