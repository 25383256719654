import React from "react";
import useToggle from "../../hooks/useToggle";

import { ChromePicker } from "react-color";

import "./ColourPicker.scss";

function ColourPicker({ updateColor, backgroundColor }) {
  const [isOpen, togglePicker] = useToggle(false);

  const { r, g, b } = backgroundColor;
  const backgroundRGB = `rgb(${r},${g},${b})`;

  return (
    <div className="ColourPicker">
      <button
        className="ColourPicker--menu"
        onClick={togglePicker}
        style={{ background: backgroundRGB }}
      ></button>
      <div
        className="ColourPicker--picker"
        style={{ display: isOpen ? "block" : "none" }}
      >
        {isOpen && (
          <ChromePicker
            color={backgroundColor}
            onChangeComplete={updateColor}
            disableAlpha
          />
        )}
      </div>
    </div>
  );
}

export default ColourPicker;
