import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import UserContext from "../../UserContext";

import "./Calendars.scss";

import axios from "../../request";

function Calendars() {
  const user = useContext(UserContext);
  const { id } = useParams();

  const [clockName, setClockName] = useState("");
  const [calendars, setCalendars] = useState([]);
  const [calendarsSelected, setCalendarsSelected] = useState({});

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (Object.entries(user).length !== 0) {
        if (user.token) {
          const { data } = await axios.get(`/api/${id}/calendars`, {
            headers: user.auth,
          });
          if (data.error) console.error(data.error);
          const { clock, calendars } = data;
          setClockName(clock.name);
          setCalendars(calendars);
          const calendarSelected = {};
          for (const calendar of calendars) {
            calendarSelected[calendar.id] = calendar.checked || false;
          }
          setCalendarsSelected(calendarSelected);
        }
      } else {
        history.replace("/signin");
      }
    })();
  }, [user, id, history]);

  const handleCalendarChange = (e) => {
    const calendar = e.target;
    setCalendarsSelected((selectedCalendars) => ({
      ...selectedCalendars,
      [calendar.name]: !selectedCalendars[calendar.name],
    }));
  };

  const handleCalendarSubmit = async (e) => {
    e.preventDefault();
    await axios.post(
      `/api/${id}/calendars`,
      { calendars: calendarsSelected },
      { headers: user.auth }
    );
    history.push(`/${id}`);
  };

  return (
    <div className="Calendars container">
      <h1>Select calendars for {clockName} clock</h1>
      <form onSubmit={handleCalendarSubmit}>
        {calendars.map((calendar) => (
          <label key={calendar.id}>
            <div
              style={{
                backgroundColor: calendar.backgroundColor,
                color: calendar.foregroundColor,
              }}
              className="Calendars--calendar"
            >
              <h4>{calendar.summary}</h4>
              <input
                name={calendar.id}
                checked={calendarsSelected[calendar.id] || false}
                onChange={handleCalendarChange}
                type="checkbox"
              />
            </div>
          </label>
        ))}
        <button>Save</button>
        <Link to="/dashboard">Return to dashboard</Link>
      </form>
    </div>
  );
}

export default Calendars;
