import React from "react";
import moment from "moment";

import "./ProgressBar.scss";

function ProgressBar({ event }) {
  const eventLength = moment(event.end.dateTime).diff(
    event.start.dateTime,
    "seconds"
  );
  const eventPassed = moment().diff(event.start.dateTime, "seconds");
  const percent = ((eventPassed / eventLength) * 100).toFixed(3);

  return (
    <div className="ProgressBar">
      <div
        className="ProgressBar--progress"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
}

export default ProgressBar;
