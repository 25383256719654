import React from "react";

import ColourPicker from "../ColourPicker/ColourPicker";
import Toggle from "react-toggle";
import "react-toggle/style.css";

import { ReactComponent as Close } from "../../assets/icons/close.svg";

import "./Settings.scss";

function Settings(props) {
  const {
    backgroundColor,
    updateColor,
    settingsOpen,
    changeSettingsOpen,
    displaySeconds,
    setSeconds,
    displayProgressBar,
    setProgressBar
  } = props;

  const closeSettings = e => {
    changeSettingsOpen(false);
  };

  return (
    <div
      className="Settings"
      style={{
        display: settingsOpen && "block"
      }}
    >
      <div className="container">
        <Close
          className="Settings--close"
          alt="close settings"
          onClick={closeSettings}
        />

        <label className="Settings--setting">
          <span>Display Seconds</span>
          <Toggle
            checked={displaySeconds}
            onChange={setSeconds}
            icons={false}
          />
        </label>

        <label className="Settings--setting">
          <span>Display Progress Bar</span>
          <Toggle
            checked={displayProgressBar}
            onChange={setProgressBar}
            icons={false}
          />
        </label>

        <label className="Settings--setting">
          <span>Change Colour</span>
          <ColourPicker
            updateColor={updateColor}
            backgroundColor={backgroundColor}
          />
        </label>
      </div>
    </div>
  );
}

export default Settings;
