import React from "react";
import ProgressBar from "../ProgressBar/ProgressBar";

import moment from "moment";

function Events({ events, displayProgressBar, quote, error }) {
  const nextEvent = events.find(
    e => e.end.dateTime && moment().isBefore(e.end.dateTime)
  );

  const quoteDisplay = (
    <h2>
      {quote.content} – <em>{quote.author}</em>
    </h2>
  );

  if (!events || !nextEvent || error) {
    if (Object.entries(quote).length > 0) {
      return quoteDisplay;
    }
    if (error)
      return (
        <h2>
          <em>{error}</em>
        </h2>
      );
    return <h2>Loading...</h2>;
  }

  const timeTillNextEvent = moment(nextEvent.start.dateTime).diff(
    moment(),
    "hours",
    true
  );

  if (timeTillNextEvent >= 6) {
    return quoteDisplay;
  } else if (timeTillNextEvent > 0) {
    // If no current event display next event within 6 hours,
    const startTime = moment(nextEvent.start.dateTime).format("h:mma");
    const endTime = moment(nextEvent.end.dateTime).format("h:mma");

    const timeTo = Math.ceil(
      moment(nextEvent.start.dateTime).diff(moment(), "minutes", true)
    );

    const timePhrase = moment.duration(timeTo, "minutes").humanize(true);

    return (
      <div>
        <h2>
          {nextEvent.summary} ({startTime} - {endTime}) starts {timePhrase}
        </h2>
      </div>
    );
  } else {
    // Gets all the events
    return events
      .filter(e =>
        moment().isBetween(e.start.dateTime, e.end.dateTime, "second", "[)")
      )
      .map(e => {
        const startTime = moment(e.start.dateTime).format("h:mma");
        const endTime = moment(e.end.dateTime).format("h:mma");
        const timeTo = Math.ceil(
          moment(e.end.dateTime).diff(moment(), "minutes", true)
        );

        const timePhrase = moment.duration(timeTo, "minutes").humanize(true);

        return (
          <div key={e.id}>
            <h2>
              {e.summary} ({startTime} - {endTime}) will end {timePhrase}
            </h2>
            {displayProgressBar && <ProgressBar event={e} />}
          </div>
        );
      });
  }
}

export default Events;
